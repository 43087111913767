export default {
    name: "Disclaimer",
    created() {
        this.$nextTick(function () {

        });
    },
    mounted() {

    },
    data() {
        return {
            url1:localStorage.getItem("langIH") == 1?'https://www.maybank-ke.co.th/media/407434/msthprivacypolicynotice-webeng_9feb2022.pdf':'https://www.maybank-ke.co.th/media/407435/msthprivacypolicynotice-webthai_9feb2022.pdf',
            url2:localStorage.getItem("langIH") == 1?'https://www.maybank-ke.co.th/en/investor-relations/good-corporate-governance/pagegroup/corporate-governance-policy/index.html':'https://www.maybank-ke.co.th/th/investor-relations/good-corporate-governance/pagegroup/corporate-governance-policy/index.html'
        };
    },
    computed: {

    },
    watch: {

    },
    methods: {

    }
};